<template>
    <div>
        <div style="background-color: #ffffff;">
            <div class="pageCenter titleBar " :class="[statu==4?'enterHeadWidth':'pageWidth']">
                <!-- <div class="pageCenter titleBar" :style="{width: pageWidth+'px'}"> -->
                <div class="titleBar_left">
                    <img @click="$oucy.replace('/', null)" style="height: 40px;cursor: pointer;" src="../../assets/logo.png">
                    <div class="titleBar_left_txt">
                        企业管理中心
                    </div>
                </div>
                <div class="titleBar_right df-ai-c">
                    <el-select v-model="enterpriseId" placeholder="请选择" filterable class="m-r-10" size="mini" @change="setUserDefaultEnterprise">
                        <el-option v-for="item in enterpriseByUserList" :key="item.id" :label="item.enterpriseName+(item.isdefault?'(默认)':'')" :value="item.id">
                        </el-option>
                    </el-select>
                    <el-button class="m-r-30" size="mini">设置默认企业</el-button>
                    <span @click="$oucy.replace('/')">返回首页</span>
                </div>
            </div>
        </div>
        <div class="pageCenter breadcrumbBar" :class="[statu==4?'enterHeadWidth':'pageWidth']">
            <!-- <div class="pageCenter breadcrumbBar" :style="{width: pageWidth+'px'}"> -->
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item><a href="/">企业管理中心</a></el-breadcrumb-item>
                <!-- <el-breadcrumb-item>个人资料</el-breadcrumb-item> -->
            </el-breadcrumb>
            <el-button icon="el-icon-notebook-2" type="text" @click="$oucy.go('/AfficheList?type=1')">帮助手册</el-button>
        </div>
        <div class="pageCenter enter" :class="[statu==4?'enterPageWidth':'pageWidth']">
            <!-- <div class="pageCenter" :style="{width: pageWidth+'px'}"> -->
            <el-container>
                <el-aside width="220px">
                    <el-menu class="el-menu-vertical-demo" router :default-openeds="['/enter','/enter/CouponManagement','/enter/product']">
                        <el-menu-item index="/enter">
                            <i class="el-icon-s-home"></i>
                            <span slot="title">企业管理中心首页</span>
                        </el-menu-item>
                        <el-menu-item index="/enter/enterpriseapply" v-if="0">
                            <i class="el-icon-office-building"></i>
                            <span slot="title">企业信息管理</span>
                        </el-menu-item>
                        <el-menu-item index="/enter/OMS">
                            <i class="el-icon-s-cooperation"></i>
                            <span slot="title">企业订单管理</span>
                        </el-menu-item>
                        <el-submenu index="/enter/product">
                            <template slot="title">
                                <i class="el-icon-files"></i>
                                <span>商品管理中心</span>
                            </template>
                            <el-menu-item index="/enter/product">商品管理</el-menu-item>
                            <el-menu-item :index="'/enter/OMSCommentManagement?enterpriseId='+enterpriseId">评价管理</el-menu-item>
                        </el-submenu>
                        <el-submenu index="/enter">
                            <template slot="title">
                                <i class="el-icon-files"></i>
                                <span>企业内容管理</span>
                            </template>
                            <!-- <el-menu-item disabled index="/enter/photo">相册管理</el-menu-item> -->
                            <!-- <el-menu-item disabled index="/enter/news">新闻管理</el-menu-item> -->
                            <el-menu-item disabled index="/enter/video">视频管理</el-menu-item>
                            <!-- <el-menu-item disabled index="/enter/VR">VR管理</el-menu-item> -->
                            <el-menu-item index="/enter/CardDetail">名片管理</el-menu-item>
                            <el-menu-item index="/enter/StaffDetail">员工管理</el-menu-item>
                            <el-menu-item index="/enter/radar">雷达线索</el-menu-item>
                        </el-submenu>
                        <el-submenu index="/acc/userinfo" v-if="0">
                            <template slot="title">
                                <i class="el-icon-files"></i>
                                <span>企业官网管理</span>
                            </template>
                            <el-menu-item>品牌官网预览</el-menu-item>
                            <el-menu-item>生成独立小程序</el-menu-item>
                        </el-submenu>
                        <el-submenu index="/enter/CouponManagement">
                            <template slot="title">
                                <i class="el-icon-files"></i>
                                <span>优惠券管理</span>
                            </template>
                            <el-menu-item index="/enter/CouponCreate">发布优惠券</el-menu-item>
                            <el-menu-item index="/enter/CouponManagement">管理优惠券</el-menu-item>
                        </el-submenu>
                        <el-menu-item index="/acc/userinfo" v-if="0">
                            <i class="el-icon-s-shop"></i>
                            <span slot="title">店铺设计</span>
                        </el-menu-item>
                        <el-menu-item index="/acc/enterprise" v-if="0">
                            <i class="el-icon-setting"></i>
                            <span slot="title">密码设置</span>
                        </el-menu-item>
                        <el-menu-item index="/acc/findpassword" v-if="0">
                            <i class="el-icon-phone"></i>
                            <span slot="title">客服中心</span>
                        </el-menu-item>
                    </el-menu>
                </el-aside>
                <el-container>
                    <div style="padding: 0 0 60px 25px;width: 100%">
                        <router-view />
                    </div>
                </el-container>
            </el-container>
        </div>
    </div>
</template>
<script>
    import oucy from "../../util/oucyUtil";
    import {userAuth} from '@/service/index.js'
    import {localSet, localGet, localDel} from "@/store/store"

    export default {
        name: "Enter",
        components: {
        },
        data() {
            return {
                pageWidth: oucy.pageWidth,
                statu:0,
                enterpriseId:null,
                enterpriseByUserList:[],

            }
        },
        computed:{

        },
       mounted(){
            // 查询客户端用户的所有企业
            this.getEnterpriseByUser()
            //监听登录事件
            this.eventBus/*.$off(this.$oucy.msgKeys.LOGIN_SUCCESS)*/.$on(this.$oucy.msgKeys.LOGIN_SUCCESS,(res)=>{
                console.log('登录成功');
                 location.reload();
            });
        },
        methods: {            
            // 查询客户端用户的所有企业
            getEnterpriseByUser(){                
                userAuth.getEnterpriseByUser({}).then(res=>{
                   console.log(res)
                   if(res && res.length){
                        this.enterpriseByUserList=res
                        let enterprise=res[0]
                        for(let v of this.enterpriseByUserList){
                            if(v.isdefault){
                                enterprise=v
                            }
                        }
                        this.enterpriseId=enterprise.id
                        // 审核未通过的企业，想进入企业管理中心的全部回到审核页
                        if(enterprise.enterpriseEnterStatus!=4){
                            this.$alert(`当前企业未通过审核${res.length>1?',您可以切换企业试试。':'请等待审核'} `,'提示')
                            this.$oucy.replace('/acc/enterprise')
                        }
                        this.getStatus(enterprise)
                   }else{
                    this.$oucy.replace('/acc/enterprise')
                   }
                })
                
            },
            // 设置默认企业 后台
            setUserDefaultEnterprise(){
                userAuth.setUserDefaultEnterprise({enterpriseId:this.enterpriseId}).then(res=>{
                    oucy.receiveUserRecordEvent({record:"切换企业"})
                    this.$message('设置成功')
                    this.setEnterprise()
                })
            },
            // 设置默认企业 本地
            setEnterprise(){
                for(let v of this.enterpriseByUserList){
                    if(v.id==this.enterpriseId){
                        localSet('enterprise',v)
                        if(v.enterpriseEnterStatus!=4){
                            this.$oucy.replace('/acc/enterprise')
                        }else{
                            location.reload();
                        }
                    }
                }

            },
            getStatus(enterprise){
                localSet('enterprise',enterprise)
                oucy.queryEnterApplyStatus({enterpriseId:enterprise.id}).then(res=>{
                    this.statu=res.status
                    this.$store.commit('setStatu',this.statu)
                })
                oucy.queryEnterpriseIdentifyStatus({enterpriseId:enterprise.id}).then(res=>{
                    this.status=res
                    this.$store.commit('setStatus',this.status)
                },err=>{
                    console.error(err)
                })
            }
        }
    }
</script>
<style scoped>
.titleBar {
    height: 100px;
    /*background-color: #0366C3;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.titleBar_left {
    /*background-color: #42b983;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.titleBar_left_txt {
    /*background-color: #409EFF;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    font-size: 25px;
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px #eeeeee solid;
    color: gray;
}

.titleBar_right {
    font-size: 25px;
    color: gray;
}

.titleBar_right:hover {
    cursor: pointer;
    color: #000000;
}

.breadcrumbBar {
    padding: 10px 0;
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.breadcrumbBar.enterHeadWidth {
    padding: 0 60px;
}

.el-menu-vertical-demo {
    text-align: left;
    min-height: 700px;
    border-radius: 4px;
}

.el-menu-item,
.el-submenu__title {
    font-size: 16px;
}

.el-menu-item span {
    font-size: 16px;
}
</style>
<style type="text/css">
.enter .el-submenu__title {
    font-size: 16px;
}
.titleBar_right .el-select>.el-input{
  width: 260px;
}
</style>